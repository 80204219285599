import * as React from 'react';
import { ApolloError } from 'apollo-boost';
import { Table, TableRow, Pagination } from 'shared/components';
import FileListItem from './FileListItem';
import { TableCellItem } from 'shared/components/TableRow';
import { TrintFile } from 'types';
import { Mutation } from 'react-apollo';
import {
  DELETE_MULTIPLE_FILES_MUTATION,
  RESTORE_MULTIPLE_FILES_MUTATION,
} from 'users/mutations';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ModalTrigger } from 'contexts/ModalTrigger';
import { DeleteOutline, RestoreFromTrash } from '@material-ui/icons';

const colWidths = [
  '5%',
  '22%',
  '22%',
  '10%',
  '10%',
  '12%',
  '12%',
  '12%',
  '12%',
];

interface MutationVars {
  fileIds: string[];
  userId: string;
}

interface RestoreMutationResult {
  deleted: boolean;
}

interface DeleteMutationResult {
  deleted: boolean;
  refunded: boolean;
}

interface Props {
  headers: TableCellItem[];
  files: TrintFile[];
  userId: string;
  isShowingDeleted: boolean;
  page: number;
  perPage: number;
  hasMore: boolean;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  onMutationCompleted?: (data: any) => void;
  onMutationError?: (error: ApolloError) => void;
}

const FileList = ({
  files = [],
  headers,
  userId,
  isShowingDeleted,
  page,
  hasMore,
  perPage,
  onChangePage,
  onChangeRowsPerPage,
  onMutationCompleted,
  onMutationError,
}: Props) => {
  const [checked, setChecked] = React.useState<string[]>([]);
  const [isMutating, setIsMutating] = React.useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          paddingBottom: '10px',
        }}
      >
        {isShowingDeleted ? (
          <Mutation<RestoreMutationResult, MutationVars>
            mutation={RESTORE_MULTIPLE_FILES_MUTATION}
            onCompleted={(data) => {
              onMutationCompleted && onMutationCompleted(data);
              setIsMutating(false);
            }}
            onError={(error) => {
              onMutationError && onMutationError(error);
              setIsMutating(false);
            }}
          >
            {(mutation) => {
              if (isMutating) return <CircularProgress />;

              return (
                <>
                  <ModalTrigger
                    modal="ConfirmDialog"
                    modalProps={{
                      title: `RESTORE ${checked.length} FILES?`,
                      message: 'Are you sure you want to restore these files?',
                      onConfirm: () => {
                        mutation({ variables: { fileIds: checked, userId } });
                        setIsMutating(isMutating);
                      },
                    }}
                  >
                    {({ openModal }) => (
                      <Tooltip
                        title={
                          checked.length < 1
                            ? 'Select files to restore'
                            : 'Restore multiple files'
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '8px',
                            margin: '0 14px 10px 0',
                          }}
                        >
                          <IconButton
                            onClick={openModal}
                            disabled={checked.length < 1}
                          >
                            <RestoreFromTrash />
                          </IconButton>
                          <Typography
                            variant="body2"
                            style={{
                              color: checked.length < 1 ? 'grey' : 'black',
                              cursor:
                                checked.length < 1 ? 'not-allowed' : 'pointer',
                            }}
                            onClick={openModal}
                          >
                            Restore multiple files
                          </Typography>
                        </div>
                      </Tooltip>
                    )}
                  </ModalTrigger>
                </>
              );
            }}
          </Mutation>
        ) : (
          <Mutation<DeleteMutationResult, MutationVars>
            mutation={DELETE_MULTIPLE_FILES_MUTATION}
            onCompleted={(data) => {
              onMutationCompleted && onMutationCompleted(data);
              setIsMutating(false);
            }}
            onError={(error) => {
              onMutationError && onMutationError(error);
              setIsMutating(false);
            }}
          >
            {(mutation) => {
              if (isMutating) return <CircularProgress />;

              return (
                <>
                  <ModalTrigger
                    modal="ConfirmDialog"
                    modalProps={{
                      title: `DELETE ${checked.length} FILES?`,
                      message:
                        'Are you sure you want to delete these files? Please note that multiple file deletion is non-refundable.',
                      onConfirm: () => {
                        mutation({ variables: { fileIds: checked, userId } });
                        setIsMutating(isMutating);
                      },
                    }}
                  >
                    {({ openModal }) => (
                      <Tooltip
                        title={
                          checked.length < 1
                            ? 'Select files to delete'
                            : 'Delete multiple files'
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '8px',
                            margin: '0 14px 10px 0',
                          }}
                        >
                          <IconButton
                            onClick={openModal}
                            disabled={checked.length < 1}
                          >
                            <DeleteOutline />
                          </IconButton>
                          <Typography
                            variant="body2"
                            style={{
                              color: checked.length < 1 ? 'grey' : 'black',
                              cursor:
                                checked.length < 1 ? 'not-allowed' : 'pointer',
                            }}
                            onClick={openModal}
                          >
                            Delete multiple files
                          </Typography>
                        </div>
                      </Tooltip>
                    )}
                  </ModalTrigger>
                </>
              );
            }}
          </Mutation>
        )}
      </div>

      <Table headers={headers} colWidths={colWidths}>
        {files.map((file) => (
          <TableRow<TrintFile>
            key={file._id}
            item={file}
            component={FileListItem}
            options={{ isShowingDeleted, userId }}
            onMutationCompleted={onMutationCompleted}
            onMutationError={onMutationError}
            checked={checked}
            setChecked={setChecked}
          />
        ))}
      </Table>
      <Pagination
        page={page}
        perPage={perPage}
        hasMore={hasMore}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  );
};

export default FileList;
