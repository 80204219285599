import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-boost';
import {
  CircularProgress,
  TableRow,
  TableCell,
  IconButton,
  Theme,
  createStyles,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Restore from '@material-ui/icons/Restore';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';
import { FormatDuration, FormatDate } from 'shared/components';
import { createStyled } from 'shared/hocs';
import { ModalTrigger } from 'contexts/ModalTrigger';
import { TrintFile } from 'types';
import { DELETE_FILE_MUTATION, RESTORE_FILE_MUTATION } from '../mutations';
import { FileAdditionalInformation } from './AdditionalFileInformation';

interface DeleteMutationVars {
  fileId: string;
  refund?: boolean;
}

interface DeleteMutationResult {
  deleted: boolean;
  refunded: boolean;
}

interface RestoreMutationVars {
  fileId: string;
}

interface RestoreMutationResult {
  deleted: boolean;
}

interface Props {
  item: TrintFile;
  options: { isShowingDeleted?: boolean; userId: string };
  onMutationCompleted?: (data: any) => void;
  onMutationError?: (error: ApolloError) => void;
  checked: string[];
  setChecked: (checked: string[]) => void;
}

const FileListItem = ({
  item,
  options,
  onMutationCompleted,
  onMutationError,
  checked,
  setChecked,
}: Props) => {
  const [
    showAdditionalInformation,
    setShowAdditionalInformation,
  ] = useState<boolean>(false);
  const [isMutating, setIsMutating] = useState(false);

  const { isShowingDeleted = false, userId } = options;
  const {
    _id: fileId,
    algorithm,
    duration,
    status,
    title,
    created,
    updated,
    uploadSource,
  } = item;

  const onCheckboxClick = (fileId: string) => {
    if (checked.includes(fileId)) {
      setChecked(checked.filter((id) => id !== fileId));
    } else {
      setChecked([...checked, fileId]);
    }
  };

  return (
    <Styled>
      {({ classes }) => (
        <>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={checked.includes(fileId)}
                tabIndex={-1}
                onChange={() => onCheckboxClick(fileId)}
              />
            </TableCell>
            <TableCell
              className={classes.title}
              onClick={() =>
                setShowAdditionalInformation(!showAdditionalInformation)
              }
              style={{ cursor: 'pointer' }}
            >
              <Tooltip title="Click for more information">
                <span>{title}</span>
              </Tooltip>
            </TableCell>
            <TableCell>{fileId}</TableCell>
            <TableCell>{uploadSource}</TableCell>
            <TableCell>{`[${algorithm}] ${status}`}</TableCell>
            <TableCell>
              <FormatDuration duration={duration} />
            </TableCell>
            <TableCell>
              <FormatDate date={created} />
            </TableCell>
            <TableCell>
              <FormatDate date={updated} />
            </TableCell>
            <TableCell className={classes.actions}>
              {isShowingDeleted && (
                <Mutation<RestoreMutationResult, RestoreMutationVars>
                  mutation={RESTORE_FILE_MUTATION}
                  onCompleted={(data) => {
                    onMutationCompleted && onMutationCompleted(data);
                    setIsMutating(false);
                  }}
                  onError={(error) => {
                    onMutationError && onMutationError(error);
                    setIsMutating(false);
                  }}
                >
                  {(mutation) => {
                    if (isMutating) return <CircularProgress />;

                    return (
                      <ModalTrigger
                        modal="ConfirmDialog"
                        modalProps={{
                          title: `RESTORE ${title}?`,
                          onConfirm: () => {
                            mutation({ variables: { fileId } });
                            setIsMutating(isMutating);
                          },
                        }}
                      >
                        {({ openModal }) => (
                          <Tooltip title="Restore">
                            <IconButton onClick={openModal}>
                              <RestoreFromTrash />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ModalTrigger>
                    );
                  }}
                </Mutation>
              )}
              {!isShowingDeleted && (
                <Mutation<DeleteMutationResult, DeleteMutationVars>
                  mutation={DELETE_FILE_MUTATION}
                  onCompleted={(data) => {
                    onMutationCompleted && onMutationCompleted(data);
                    setIsMutating(false);
                  }}
                  onError={(error) => {
                    onMutationError && onMutationError(error);
                    setIsMutating(false);
                  }}
                >
                  {(mutation) => {
                    if (isMutating) return <CircularProgress />;

                    return (
                      <>
                        <ModalTrigger
                          modal="ConfirmDialog"
                          modalProps={{
                            title: `DELETE ${title}?`,
                            onConfirm: () => {
                              mutation({ variables: { fileId } });
                              setIsMutating(isMutating);
                            },
                          }}
                        >
                          {({ openModal }) => (
                            <Tooltip title="Delete">
                              <IconButton onClick={openModal}>
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ModalTrigger>
                        <ModalTrigger
                          modal="ConfirmDialog"
                          modalProps={{
                            title: `DELETE ${title} AND REFUND?`,
                            onConfirm: () => {
                              mutation({
                                variables: { fileId, refund: true },
                              });
                              setIsMutating(isMutating);
                            },
                          }}
                        >
                          {({ openModal }) => (
                            <Tooltip title="Delete & refund">
                              <IconButton onClick={openModal}>
                                <Restore />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ModalTrigger>
                      </>
                    );
                  }}
                </Mutation>
              )}
            </TableCell>
          </TableRow>
          {showAdditionalInformation && (
            <FileAdditionalInformation item={item} userId={userId} />
          )}
        </>
      )}
    </Styled>
  );
};

const Styled = createStyled((theme: Theme) =>
  createStyles({
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    actions: {
      whiteSpace: 'nowrap',
    },
  }),
);

export default FileListItem;
