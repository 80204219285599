import * as React from 'react';
import { Query } from 'react-apollo';
import OrganisationTable from 'organisations/components/OrganisationList';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import ListViewLayout from 'shared/layout/ListViewLayout';
import { ListViewSearchArea } from 'shared/layout';
import { Button, Input, createStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/PlusOne';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import { GlobalCircleLoader, ListPage } from 'shared/components';
import {
  ALL_ORGANISATIONS_QUERY,
  AllOrganisationsQueryResult,
} from 'organisations/queries';
import { ListPageRenderProps, SortDirection } from 'shared/components/ListPage';
import OrganisationCreateModal from 'organisations/components/OrganisationCreateModal';
import CustomToast from 'shared/components/CustomToast';
import { SearchTips } from 'shared/components/OrganisationSearchTips';

interface QueryDefinition {
  search?: string;
  order: SortDirection;
  sort: string;
  perPage: number;
  page: number;
}

interface QueryVars {
  searchText?: string;
  page: number;
  limit: number;
  order: SortDirection;
  sortBy?: string;
}

interface State {
  value: string;
  isInCreateMode: boolean;
  notification: string;
}

interface QueryRes {
  groups: {
    list: AllOrganisationsQueryResult[];
    hasMore: boolean;
  };
}

type Props = QueryRouteComponentProps<QueryDefinition> &
  ListPageRenderProps<{ search?: string }>;

class Inner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: '',
      isInCreateMode: false,
      notification: '',
    };
  }

  componentDidUpdate(pp: Props, ps: State) {
    const prev = pp.query.params.search || '';
    const { search = '' } = this.props.query.params;
    if (prev && prev !== search) {
      this.setState({ value: search });
    }
  }

  componentDidMount() {
    const { search = '' } = this.props.searchValues;
    this.setState({ value: search });
  }

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ value });
  };

  search = () => {
    const { value } = this.state;
    const { updateSearch, clearSearch } = this.props;
    if (value) {
      updateSearch({ search: value });
    } else {
      clearSearch();
    }
  };

  createOrg = () => this.setState({ isInCreateMode: true });

  closeCreateDrawer = () => this.setState({ isInCreateMode: false });

  onCreate = () => {
    this.setState({ notification: 'Successfully created organisation' });
  };

  render() {
    const {
      paging,
      searchValues,
      changeRowsPerPage,
      handleChangePage,
    } = this.props;
    const { search: searchText } = searchValues;
    const { order, sortBy, page, perPage: limit } = paging;

    const headers = [
      create.header('Organisation'),
      create.header('Id'),
      create.header('Active Members'),
      create.header('Org Type'),
      create.header('Actions'),
    ];

    return (
      <Style>
        {({ classes }) => (
          <ListViewLayout>
            <SearchTips />
            <ListViewSearchArea>
              <Input
                fullWidth
                placeholder="Search by organisation name..."
                value={this.state.value}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                disableUnderline
                className={classes.searchInput}
              />

              <Button onClick={this.search} variant="contained" color="primary">
                <SearchIcon />
              </Button>

              <Button
                style={{ marginLeft: '1%' }}
                onClick={this.createOrg}
                variant="contained"
                color="primary"
              >
                <AddIcon />
              </Button>
            </ListViewSearchArea>

            <Query<QueryRes, QueryVars>
              query={ALL_ORGANISATIONS_QUERY}
              fetchPolicy="cache-and-network"
              variables={{
                page,
                order,
                limit,
                sortBy,
                searchText,
              }}
            >
              {({ loading, data, error }) => {
                if (error) return null;
                if (loading && (!data || !data.groups))
                  return <GlobalCircleLoader show />;

                return (
                  <>
                    <OrganisationTable
                      page={page}
                      perPage={limit}
                      headers={headers}
                      groups={data!.groups.list}
                      hasMore={data!.groups.hasMore}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={changeRowsPerPage}
                    />
                    <GlobalCircleLoader show={loading} />
                  </>
                );
              }}
            </Query>

            <OrganisationCreateModal
              onCreate={this.onCreate}
              onClose={this.closeCreateDrawer}
              isOpen={this.state.isInCreateMode}
              cancel={this.closeCreateDrawer}
            />

            {this.state.notification && (
              <CustomToast message={this.state.notification} isOpen />
            )}
          </ListViewLayout>
        )}
      </Style>
    );
  }
}

interface SearchQuery {
  search?: string;
}

const searchKeys: Array<keyof SearchQuery> = ['search'];
const OrganisationListPage: React.SFC<Props> = (props) => {
  return (
    <ListPage<SearchQuery> searchKeys={searchKeys}>
      {(query) => <Inner {...query} {...props} />}
    </ListPage>
  );
};

export default OrganisationListPage;

const Style = createStyled((theme: Theme) =>
  createStyles({
    searchInput: {
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
    },
  }),
);
