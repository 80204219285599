import React from 'react';
import { createStyles } from '@material-ui/core';
import { createStyled } from 'shared/hocs';

export const SearchTips = () => {
  return (
    <Styled>
      {({ classes }) => (
        <div className={classes.tips}>
          <details>
            <summary>Toggle for search tips</summary>
            <br />
            <div>
              Prefix your search with the following for specific queries:
            </div>
            <ul>
              <li>
                <strong>org:</strong> - Search by organisation ID only{' '}
                <i>(org:orgId123)</i>
              </li>
              <li>
                <strong>con:</strong> - Search by SSO connection string{' '}
                <i>(con:trintdev-waad)</i>
              </li>
              <li>
                <strong>domain:</strong> - Search by SSO domain{' '}
                <i>(domain:trint.com)</i>
              </li>
            </ul>
          </details>
        </div>
      )}
    </Styled>
  );
};

const Styled = createStyled(() => {
  return createStyles({
    tips: {
      borderRadius: '5px',
      padding: '20px',
      backgroundColor: '#EDDAF7',
      fontFamily: 'Roboto',
    },
  });
});
