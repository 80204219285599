import gql from 'graphql-tag';
import {
  Allowance,
  TrintFile,
  StripeCustomer,
  TrintGroup,
  TrintUser,
  TrintUserProfile,
  PlanDetails,
  GroupMember,
} from 'types';
import User from 'fragments/User';

export type GeneralSearchQueryResult = Pick<
  TrintUser,
  '_id' | 'username' | 'userType' | 'organisationAdmin'
> & {
  profile: Pick<TrintUserProfile, 'firstName' | 'lastName' | 'jobTitle'>;
  allowance: Pick<Allowance, 'balance'>;
  customer: Pick<StripeCustomer, 'id'>;
  group: Pick<TrintGroup, '_id' | 'owner' | 'admins' | 'ownerId'>;
  disabled: boolean;
};

export interface TrintStatsForUser {
  username: string;
  userId: string;
  trintCount: number;
  duration: number;
  firstCreatedAt: string;
  lastCreatedAt: string;
}

export const GENERAL_SEARCH_QUERY = gql`
  query users(
    $searchText: String
    $groupId: String
    $teamId: String
    $page: Int!
    $limit: Int!
    $order: String
    $sortBy: String
  ) {
    users(
      searchText: $searchText
      groupId: $groupId
      teamId: $teamId
      page: $page
      limit: $limit
      order: $order
      sortBy: $sortBy
    ) {
      list {
        ...UserBase
        ...UserGroupInfo
        allowance {
          balance
        }
        profile {
          firstName
          lastName
        }
        customer {
          id
        }
      }
      hasMore
    }
  }
  ${User.fragments.userBase}
  ${User.fragments.userGroupInfo}
`;

export type ProfileQueryResult = TrintUser;

export const PROFILE_QUERY = gql`
  query profile($userId: String!) {
    user(userId: $userId) {
      ...UserBase
      profile {
        firstName
        lastName
        industry
        companySize
        country
        city
        company
        department
        jobTitle
      }
    }
    getUserFeatureFlags(userId: $userId) {
      featureFlags
    }
  }
  ${User.fragments.userBase}
`;

export interface UserBillingQueryResult {
  user: Pick<
    TrintUser,
    '_id' | 'username' | 'billing' | 'version' | 'customer'
  > & {
    currentSubscription: Pick<
      PlanDetails,
      'planId' | 'trial' | 'vip' | 'payg' | 'title' | 'pricingInterval'
    >;
  } & {
    group: Pick<GroupMember, '_id' | 'ownerId'>;
  };
}

export interface UserBillingQueryVars {
  userId: String;
}

export const USER_BILLING_QUERY = gql`
  query billing($userId: String!) {
    user(userId: $userId) {
      ...UserBase
      version
      group {
        _id
        ownerId
      }
      billing {
        currency
        first_name
        last_name
        company
        vat_number
        address_line1
        address_city
        address_zip
        address_country
        plan
        nextPlan
        nextPlanUpgrade
        last4
        brand
        paymentMethodId
        exp_month
        exp_year
        delinquent
        path
        pendingOperations {
          id
          type
          executeAt
          data {
            nextSeats
            nextPlanId
            basePlanId
            paymentIntentId
          }
        }
        joinInvoiceItem
        v2 {
          cancellationDate
          downgradeDate
          subscriptionEnd
          subscriptionStatus
        }
        purchaseSource
      }
      currentSubscription {
        id
        planId
        trial
        title
        currency
        planCode
        payg
        vip
        pricingInterval
        pricing {
          price
        }
      }
      customer {
        id
      }
    }
  }
  ${User.fragments.userBase}
`;

export type UserAllowanceQueryResult = Pick<
  TrintUser,
  '_id' | 'username' | 'allowance' | 'billing'
>;
export const USER_ALLOWANCE_QUERY = gql`
  query allowance($userId: String!) {
    user(userId: $userId) {
      ...UserBase
      billing {
        subscriptionFrozen
      }
      allowance {
        balance
        dailyUsage
        end
        expectedUsage
        extra
        plan
        planCode
        seconds
        start
        subscriptionDay
        usage
        usageByPlan
        credit {
          planAllowance
          bonus
        }
      }
    }
  }
  ${User.fragments.userBase}
`;

export type UserBalanceQueryResult = Pick<TrintUser, '_id' | 'username'> & {
  allowance: Pick<Allowance, 'balance'>;
};
export const USER_BALLANCE_QUERY = gql`
  query allowance($userId: String!) {
    user(userId: $userId) {
      ...UserBase
      allowance {
        balance
      }
    }
  }
  ${User.fragments.userBase}
`;

export type UserGroupQueryResult = Pick<TrintUser, '_id' | 'currentTeam'> & {
  group: Pick<TrintGroup, '_id' | 'owner' | 'admins' | 'metadata'>;
};
export interface FeatureFlagsQueryResult {
  featureFlags: string[];
}

export const USER_GROUP_QUERY = gql`
  query group($userId: String!) {
    user(userId: $userId) {
      _id
      currentTeam
      ...UserGroupInfo
    }
  }
  ${User.fragments.userGroupInfo}
`;

export interface UserFilesQueryVars {
  userId: string;
  deleted?: boolean;
  searchText?: string;
  projectId?: string;
  page: number;
  limit: number;
  order?: string;
  sortBy?: string;
}

export interface UserFilesQueryResult {
  files: {
    list: TrintFile[];
    hasMore: boolean;
  };
}

export const USER_FILES_QUERY = gql`
  query files(
    $userId: String!
    $deleted: Boolean
    $projectId: String
    $searchText: String
    $page: Int!
    $limit: Int!
    $order: String
    $sortBy: String
  ) {
    files(
      userId: $userId
      deleted: $deleted
      projectId: $projectId
      searchText: $searchText
      page: $page
      limit: $limit
      order: $order
      sortBy: $sortBy
    ) {
      list {
        _id
        algorithm
        duration
        status
        title
        created
        updated
        uploadSource
      }
      hasMore
    }
  }
`;

export const GET_ADDITIONAL_FILE_INFORMATION_QUERY = gql`
  query file($fileId: String!) {
    file(fileId: $fileId) {
      format
      collaborators {
        _id
        username
        type
      }
      sharedDrive {
        _id
        name
        deleted
        archived
      }
      uploadSize
    }
  }
`;
