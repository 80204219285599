import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Query } from 'react-apollo';
import { TrintFile } from 'types';
import { GET_ADDITIONAL_FILE_INFORMATION_QUERY } from 'users/queries';

interface GetFileQueryVars {
  fileId: string;
}

interface GetFileQueryResult {
  file: {
    format: string;
    collaborators: { _id: string; username: string; type: string }[];
    sharedDrive: { name: string; id: string };
    uploadSize: number;
  };
}

export const FileAdditionalInformation = ({
  item,
  userId,
}: {
  item: TrintFile;
  userId: string;
}) => {
  return (
    <Query<GetFileQueryResult, GetFileQueryVars>
      query={GET_ADDITIONAL_FILE_INFORMATION_QUERY}
      variables={{ fileId: item._id }}
    >
      {({ data, error, loading }) => {
        if (error || loading) return null;
        const { format, collaborators, sharedDrive, uploadSize } = data!.file;

        const allCollaborators = collaborators.filter(
          (collaborator) => collaborator._id !== userId,
        );

        return (
          <TableRow
            style={{
              backgroundColor: 'lightgray',
              display: 'flex',
              margin: '10px 10px',
              alignItems: 'center',
              borderBottom: '1px solid black',
              height: 'auto',
              width: '600px',
            }}
          >
            <TableCell>
              <div>
                <ul>
                  <li>File name: {item.title}</li>
                  <li>File format: {format}</li>
                  {/* TO DO: refer unicorn user to Shared Drive tab once implemented to prevent long requests from timing out for shared drives with lots of members */}
                  <li>
                    Collaborators:{' '}
                    {allCollaborators.length > 0 ? (
                      <ul>
                        {allCollaborators.map((collaborator, i) => (
                          <li key={i}>
                            {collaborator.username} - {collaborator.type}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      'none'
                    )}
                  </li>
                  <li>
                    Location:{' '}
                    {sharedDrive
                      ? `Shared Drive: (${sharedDrive.name})`
                      : 'My Drive'}
                  </li>
                  <li>
                    Batch upload size:{' '}
                    {uploadSize ? `${uploadSize} bytes` : 'N/A'}
                  </li>
                </ul>
              </div>
            </TableCell>
          </TableRow>
        );
      }}
    </Query>
  );
};
