import gql from 'graphql-tag';

export const CREATE_USER_MUTATION = gql`
  mutation createUser($input: InputCreateUserData!) {
    createUser(input: $input)
  }
`;

export const SYNC_USER_FROM_STRIPE_MUTATION = gql`
  mutation syncUserFromStripeV2($userId: String!) {
    syncUserFromStripeV2(userId: $userId) {
      _id
      version
      group {
        _id
      }
      billing {
        currency
        first_name
        last_name
        company
        vat_number
        address_line1
        address_city
        address_zip
        address_country
        plan
        nextPlan
        nextPlanUpgrade
        last4
        brand
        paymentMethodId
        exp_month
        exp_year
        delinquent
        path
        joinInvoiceItem
      }
      currentSubscription {
        id
        planId
        trial
        title
        currency
        planCode
        payg
        vip
        pricingInterval
        pricing {
          price
        }
      }
      customer {
        id
      }
    }
  }
`;

export const USER_ALLOCATE_BONUS = gql`
  mutation allocateBonus($userId: String!, $minutes: Float!, $reason: Int) {
    allocateBonus(userId: $userId, minutes: $minutes, reason: $reason) {
      user
      group
      type
      created
      currency
      reason
    }
  }
`;

export const USER_ADJUST_END_DATE = gql`
  mutation extendAllowanceDate($userId: String!, $date: String!) {
    extendAllowanceDate(userId: $userId, date: $date) {
      start
      end
    }
  }
`;

export const DELETE_FILE_MUTATION = gql`
  mutation deleteFile($fileId: String!, $refund: Boolean) {
    deleteFile(fileId: $fileId, refund: $refund) {
      deleted
      refunded
    }
  }
`;

export const DELETE_MULTIPLE_FILES_MUTATION = gql`
  mutation deleteFiles($fileIds: [String]!, $userId: String!) {
    deleteFiles(fileIds: $fileIds, userId: $userId) {
      deleted
      filesUnableToDelete
      refunded
    }
  }
`;

export const RESTORE_FILE_MUTATION = gql`
  mutation restoreFile($fileId: String!) {
    restoreFile(fileId: $fileId) {
      deleted
    }
  }
`;

export const RESTORE_MULTIPLE_FILES_MUTATION = gql`
  mutation restoreFiles($fileIds: [String]!, $userId: String!) {
    restoreFiles(fileIds: $fileIds, userId: $userId) {
      deleted
    }
  }
`;

export const REMOVE_MEMBER_FROM_GROUP = gql`
  mutation removeMemberFromGroup($groupId: String!, $memberId: String!) {
    removeMemberFromGroup(groupId: $groupId, memberId: $memberId) {
      _id
    }
  }
`;

export const CANCEL_SUBSCRIPTION_V2 = gql`
  mutation cancelSubscriptionV2($userId: String!) {
    cancelSubscriptionV2(userId: $userId) {
      _id
      billing {
        v2 {
          subscriptionStatus
        }
      }
    }
  }
`;
